import { Card, Col, Row, Button, message } from 'antd';
import { SyncOutlined, DownloadOutlined, LinkOutlined } from '@ant-design/icons';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// API
import { store } from '@/redux/store';
import APIService from '@/services/API';
import LeadsCard from '@/components/dashboard/LeadsCard';

const getToken = () => store.getState().auth.token;
const getApiUrl = () => import.meta.env.VITE_APP_API_URL;

const HomeScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    slug: 'leading-config',
  });
  const [leadingId, setLeadingId] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [loading, setLoading] = useState(false);

  const { isLoading, data, refetch } = useQuery(['configs', params], () =>
    APIService.getConfigs(params),
  );

  useEffect(() => {
    if (data?.data.length) {
      const firstData = data.data[0];
      const elementId = firstData.elements.find((item) => item.slug == 'leadingre_id');
      if (elementId) {
        setLeadingId(elementId.value);
      }

      const elementUpdated = firstData.elements.find((item) => item.slug == 'updated_at');
      if (elementUpdated) {
        const date = new Date(elementUpdated.value);
        setUpdatedAt(date.toLocaleDateString());
      }
    }
  }, [data]);

  const onSyncronize = async () => {
    setLoading(true);
    const response = await APIService.syncLeading();
    if (response.ok) {
      refetch();
      message.success(t('dashboard.successLeadingMessage'));
    } else {
      message.error(response.data?.message);
    }
    setLoading(false);
  };

  const dowloadXMLLead = async () => {
    setLoading(true);
    const response = await fetch(`${getApiUrl()}/sync-leading`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ download: true }),
    });

    if (!response.ok) {
      message.error('Something was wrong when download the file');
    } else {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `leading_xml_file_${new Date().getTime()}.xml`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    setLoading(false);
  };

  return (
    <div className="dashboard-screen fadeIn">
      <Row>
        <Col>
          <h2 style={{ marginBottom: 10 }}>{t('dashboard.title')}</h2>
        </Col>
      </Row>
      {leadingId && (
        <Row style={{ marginBottom: '2em' }}>
          <Col sm={24} md={12} lg={8} xl={6}>
            <Card
              loading={isLoading}
              actions={[
                <Button
                  loading={loading}
                  key="sendBtn"
                  type="link"
                  onClick={onSyncronize}
                >
                  <SyncOutlined /> {t('dashboard.send')}
                </Button>,
                <Button
                  loading={loading}
                  key="downloadBtn"
                  type="link"
                  onClick={dowloadXMLLead}
                >
                  <DownloadOutlined />
                </Button>,
                <Button
                  loading={loading}
                  key="listingBtn"
                  type="link"
                  target="_blank"
                  href={`https://www.leadingre.com/listings/memberlistings/${leadingId}`}
                >
                  <LinkOutlined />
                </Button>,
              ]}
              style={{ width: '100%' }}
            >
              <Card.Meta
                title={t('dashboard.syncLeading')}
                description={
                  <>
                    <p>
                      <strong>Leading ID: </strong>
                      {leadingId}
                    </p>
                    <p>
                      <strong>Last Updated: </strong>
                      {updatedAt}
                    </p>
                  </>
                }
              />
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <LeadsCard />
        </Col>
      </Row>
    </div>
  );
};

export default HomeScreen;
