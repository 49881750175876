import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { Modal, Form, Row, Col, List, Avatar, Select, Input, message } from 'antd';

import { RawLead, PatchLead } from '@/interfaces/lead.interface';
import { useAuth } from '@/hooks/auth.hook';
import APIService from '@/services/API';

export interface NotesModalProps {
  visible: boolean;
  data: RawLead | null;
  onCancel: () => void;
  onOk: () => void;
}

const NotesModal = ({ visible, data, onCancel, onOk }: NotesModalProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      formRef.setFieldValue('status', data.status);
    }
  }, [data, formRef]);

  const handleOnSubmit = async (values: any) => {
    setLoading(true);

    const patchData: PatchLead = {
      status: values.status,
      $push: {
        notes: {
          user_id: user._id.toString(),
          comment: values.comment,
        },
      },
    };

    const response = await APIService.updateLead(data?._id.toString(), patchData);
    if (response.ok) {
      onOk();
      formRef.resetFields();
      message.success(t('leads.newComment'));
    } else {
      message.error(`${response.data?.name}: ${response.data?.message}`);
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={t('leads.note')}
      okText={t('leads.addComment')}
      okButtonProps={{ loading }}
      width={800}
      onOk={() => formRef.submit()}
      onCancel={() => {
        formRef.resetFields();
        onCancel();
      }}
    >
      <Row
        className="notes-list-modal"
        wrap={false}
        justify="space-between"
        align="stretch"
      >
        <Col xs={24} md={14}>
          {data?.notes && (
            <List
              className="list-notes"
              itemLayout="horizontal"
              dataSource={data.notes}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      item?.picture ? (
                        <Avatar src={item.picture} />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )
                    }
                    title={<a>{item?.user_name ? item.user_name : data.full_name}</a>}
                    description={item.comment}
                  />
                  <small>{new Date(item?.date).toLocaleDateString()}</small>
                </List.Item>
              )}
            ></List>
          )}
        </Col>
        <Col xs={24} md={10}>
          <Form
            className="comment-form"
            form={formRef}
            layout="vertical"
            autoComplete="off"
            onFinish={handleOnSubmit}
            initialValues={{
              status: 'new',
              comment: '',
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={t('general.status')}
                  name="status"
                  rules={[{ required: true, message: <>{t('general.requiredField')}</> }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={[
                      {
                        label: t('leadStatus.new'),
                        value: 'new',
                      },
                      {
                        label: t('leadStatus.contacted'),
                        value: 'contacted',
                      },
                      {
                        label: t('leadStatus.in-progress'),
                        value: 'in-progress',
                      },
                      {
                        label: t('leadStatus.on-hold'),
                        value: 'on-hold',
                      },
                      {
                        label: t('leadStatus.not-interested'),
                        value: 'not-interested',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={t('leads.comment')}
                  name="comment"
                  rules={[{ required: true, message: <>{t('general.requiredField')}</> }]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default NotesModal;
