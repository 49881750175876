import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider, Row, message } from 'antd';

import APIService from '@/services/API';
import { RawLead } from '@/interfaces/lead.interface';
import LeadsTable from '@/components/tables/Leads.table';
import NotesModal from '@/components/leads/NotesModal';

const LeadsCard = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState({
    'status[$nin][]': 'archived',
    $skip: '0',
    $limit: '5',
    '$sort[created_at]': '-1',
  });
  const [selected, setSelected] = useState<RawLead | null>(null);
  const [visible, setVisible] = useState(false);

  const { isLoading, data, refetch } = useQuery(['leads', params], () =>
    APIService.getLeads(params),
  );

  function openNotes(data: RawLead) {
    setSelected(data);
    setVisible(true);
  }

  async function archivedLead(id: string) {
    const response = await APIService.updateLead(id, { status: 'archived' });

    if (response.ok) {
      message.success(t('leads.archivedSuccess'));
      setParams({
        'status[$nin][]': 'archived',
        $skip: '0',
        $limit: '5',
        '$sort[created_at]': '-1',
      });
      refetch();
    } else {
      message.error(`${response.data?.name}: ${response.data?.message}`);
    }
  }

  return (
    <Card style={{ width: '100%' }}>
      <Row justify="space-between" align="middle">
        <Col>
          <h2>{t('leads.title')}</h2>
        </Col>
      </Row>
      <Divider />
      <NotesModal
        visible={visible}
        data={selected}
        onOk={() => {
          refetch();
          setSelected(null);
          setVisible(false);
        }}
        onCancel={() => {
          setSelected(null);
          setVisible(false);
        }}
      />
      <LeadsTable
        data={data?.data}
        loading={isLoading}
        total={data?.total}
        onFilter={setParams}
        onOpenNotes={openNotes}
        onArchived={archivedLead}
      />
    </Card>
  );
};

export default LeadsCard;
