import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType } from 'antd/es/table';
import { FileTextOutlined, DeleteOutlined } from '@ant-design/icons';

import { RawLead } from '@/interfaces/lead.interface';

export interface LeadsTableProps {
  data?: RawLead[];
  total?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onOpenNotes?: (data: RawLead) => void;
  onArchived?: (id: string) => Promise<void>;
}

const LeadsTable = ({
  data,
  total,
  loading,
  onFilter,
  onOpenNotes,
  onArchived,
}: LeadsTableProps) => {
  const { t } = useTranslation();

  const Columns: ColumnsType<RawLead> = [
    {
      title: t('leads.fullName'),
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: t('leads.interesting'),
      dataIndex: 'property_type',
      key: 'property_type',
    },
    {
      title: t('leads.mobile'),
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: t('leads.email'),
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => {
        return (
          <Button
            type="link"
            target="_blank"
            href={`mailto:${email}`}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {email}
          </Button>
        );
      },
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => t(`leadStatus.${status}`),
    },
    {
      title: t('general.date'),
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder: 'descend',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (_id: string, record: RawLead) => (
        <Space size="middle">
          <Button type="link" onClick={() => onOpenNotes?.(record)}>
            <FileTextOutlined />
          </Button>
          <Button type="primary" onClick={() => onArchived?.(record._id)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any = {}) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...value,
        $skip: pagination.current ? (pagination.current - 1) * 5 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 5,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default LeadsTable;
